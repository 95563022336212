// import React, { Component, Fragment } from 'react'
// import { Router, Route, Switch } from "react-router";
// import HomePage from '../pages/HomePage';

// class AppRoute extends Component {
//      render() {
//           return (
//                <Fragment>
//                     <Switch>
//                          <Route exact to="/" component={HomePage} />
//                     </Switch>

//                </Fragment>
//           )
//      }
// }

// export default AppRoute
import React, { Component, Fragment } from "react";
import { Router, Routes, Route } from "react-router-dom";
import axios from 'axios' 
// import { Switch, BrowserRouter  } from "react-router";
// import { Router, Route, Switch } from "react-router";
// import CartPage from '../pages/CartPage';
import AppURL from '../api/AppURL';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
const LazyIndex = React.lazy(() => import("../pages/HomePage"));
const LazyLogin = React.lazy(() => import("../pages/UserLoginPage"));
const LazyRegister = React.lazy(() => import("../pages/RegisterPage"));
const LazySellerLogin = React.lazy(() => import("../pages/SellerLoginPage"));
const LazySellerRegister = React.lazy(() => import("../pages/SellerRegisterPage"));
const LazyContact = React.lazy(() => import("../pages/ContactPage"));
const LazyFavourite = React.lazy(() => import("../pages/FavouritePage"));
const LazyNotificationPage = React.lazy(() =>
  import("../pages/NotificationPage")
);
const LazyPrivacyPage = React.lazy(() => import("../pages/PrivacyPage"));
const LazyPurchasePage = React.lazy(() => import("../pages/PurchasePage"));
const LazyRefundPage = React.lazy(() => import("../pages/RefundPage"));
const LazyProductDetailsPage = React.lazy(() =>
  import("../pages/ProductDetailsPage")
);
const LazyCartPage = React.lazy(() => import("../pages/CartPage"));
const LazyOrderListPage = React.lazy(() => import("../pages/OrderListPage"));
const LazyProductCategoryPage = React.lazy(() =>
  import("../pages/ProductCategoryPage")
);
const LazyProductSubCategoryPage = React.lazy(() =>
  import("../pages/ProductSubCategoryPage")
);
const LazyForgetPasswordPage = React.lazy(() =>
  import("../pages/ForgetPasswordPage")
);


const LazyResetPasswordPage = React.lazy(() => import("../pages/ResetPasswordPage"));

const LazyProfilePage = React.lazy(() => import("../pages/ProfilePage"));
const LazyShopPage = React.lazy(() => import("../pages/ShopPage"));

const LazyAuctionPage = React.lazy(() => import("../pages/AuctionPage"));

const LazyAuctionDetailsPage = React.lazy(() => import("../pages/AuctionDetailsPage"));
const LazySellerPage = React.lazy(() => import("../pages/SellerPage"));
const LazyEventPage = React.lazy(() => import("../pages/EventPage"));

const LazyEventDetailsPage = React.lazy(() => import("../pages/EventDetailsPage"));
const LazyAboutPage = React.lazy(() => import("../pages/AboutPage")); 
const LazyVendorVerificationPage = React.lazy(() => import("../pages/VendorVerification"));
const LazySearchPage = React.lazy(() => import("../pages/SearchPage"));
const LazyVerifyPage = React.lazy(() => import("../pages/VerifyPage"));
const LazyVendorVerifyPage = React.lazy(() => import("../pages/VendorVerifyPage"));
const LazyTermsandConditions = React.lazy(() => import("../pages/TermsandConditionsPage"));
const LazyVendorProfile = React.lazy(() => import("../pages/VendorProfilePage"));


// import AboutPage from '../pages/AboutPage';


// import ProductDetailsPage from '../pages/ProductDetailsPage';
 
// import RegisterPage from '../pages/RegisterPage';
// import ResetPasswordPage from '../pages/ResetPasswordPage';
// import SearchPage from '../pages/SearchPage';
// import UserLoginPage from '../pages/UserLoginPage';

// import NavMenuDesktop from '../components/common/NavMenuDesktop';
// import OrderListPage from '../pages/OrderListPage';

class AppRoute extends Component {

     constructor(){
          super();
          this.state={
               user:{}
          }
     }


     componentDidMount(){
          axios.get(AppURL.UserData).then((response) => { 
               this.setUser(response.data);
               console.log('user',response.data);
          }).catch(error=>{

          });
     }


     setUser = (user) => {
          this.setState({user:user})
          localStorage.setItem('email' , this?.state?.user?.email)
     }

     

  render() {
     console.log('route@@@',this.state.user.email);
      // const User = this.props.user;
    return (
      <Fragment>
          <NavMenuDesktop  user={this.state.user} setUser={this.setUser} />  

        <Routes>
          {/* <Route exact to="/" component={HomePage} /> */}
          <Route
            path="/"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyIndex></LazyIndex>
              </React.Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyLogin  user={this.state.user} setUser={this.setUser}></LazyLogin>
              </React.Suspense>
            }
          />
          <Route
            path="/Register"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyRegister user={this.state.user} setUser={this.setUser}></LazyRegister>
              </React.Suspense>
            }
          />

<Route
            path="/VendorVerify"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >

                <LazyVendorVerifyPage></LazyVendorVerifyPage>
              </React.Suspense>
            }
          />
          
<Route
            path="/Verify"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >

                <LazyVerifyPage></LazyVerifyPage>
              </React.Suspense>
            }
          />

<Route
            path="/Sellerlogin"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazySellerLogin></LazySellerLogin>
                {/* <LazyLogin  user={this.state.user} setUser={this.setUser}></LazyLogin> */}
              </React.Suspense>
            }
          />
          <Route
            path="/SellerRegister"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazySellerRegister></LazySellerRegister>
                {/* <LazyRegister user={this.state.user} setUser={this.setUser}></LazyRegister> */}
              </React.Suspense>
            }
          />

          <Route
            path="/contact"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyContact></LazyContact>
              </React.Suspense>
            }
          />

          <Route
            path="/Purchase"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyPurchasePage></LazyPurchasePage>
              </React.Suspense>
            }
          />
          <Route
            path="/Privacy"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyPrivacyPage></LazyPrivacyPage>
              </React.Suspense>
            }
          />

          <Route
            path="/Notification"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyNotificationPage></LazyNotificationPage>
              </React.Suspense>
            }
          />

          <Route
            path="/Favourite"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyFavourite user={this.state.user} ></LazyFavourite>
              </React.Suspense>
            }
          />
          <Route
            path="/Refund"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyRefundPage></LazyRefundPage>
              </React.Suspense>
            }
          />
          <Route
            path="/TermsandConditions"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyTermsandConditions> </LazyTermsandConditions>
              </React.Suspense>
            }
          />
          <Route
            path="/auction"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
               <LazyAuctionPage></LazyAuctionPage>
                {/* <LazyShopPage user={this.state.user}  ></LazyShopPage> */}
              </React.Suspense>
            }
          />
          <Route
            path="/auctiondetails/:code"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
               <LazyAuctionDetailsPage user={this.state.user} ></LazyAuctionDetailsPage>
                {/* <LazyShopPage user={this.state.user}  ></LazyShopPage> */}
              </React.Suspense>
            }
          />
          <Route
            path="/Events"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
               <LazyEventPage></LazyEventPage>
                {/* <LazyShopPage user={this.state.user}  ></LazyShopPage> */}
              </React.Suspense>
            }
          />
          <Route
            path="/eventdetails/:code"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
               {/* <LazyAuctionDetailsPage user={this.state.user} ></LazyAuctionDetailsPage> */}
               <LazyEventDetailsPage user={this.state.user}></LazyEventDetailsPage>
              </React.Suspense>
            }
          />
          <Route
            path="/shop"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyShopPage></LazyShopPage>
                {/* <LazyShopPage user={this.state.user}  ></LazyShopPage> */}
              </React.Suspense>
            }
          />



          <Route
            path="/productdetails/:code"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyProductDetailsPage user={this.state.user}  ></LazyProductDetailsPage>
              </React.Suspense>
            }
          />
           {/* <Route exact path="/cart" render={(props) => <CartPage user={this.state.user} {...props} key={Date.now()} /> } /> */}


          <Route
            path="/Cart"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyCartPage user={this.state.user}></LazyCartPage>
              </React.Suspense>
            }
          />
          <Route
            path="/orderlist"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }   
              >
                <LazyOrderListPage user={this.state.user} ></LazyOrderListPage>
              </React.Suspense>
            }
          />
          <Route
            path="/productcategory/:category"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyProductCategoryPage></LazyProductCategoryPage>
              </React.Suspense>
            }
          />
          <Route
            path="/subproductcategory"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyProductSubCategoryPage></LazyProductSubCategoryPage>
              </React.Suspense>
            }
          />
          <Route
            path="/forget"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyForgetPasswordPage></LazyForgetPasswordPage>
              </React.Suspense>
            }
          />
          <Route
            path="/reset/:id"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyResetPasswordPage></LazyResetPasswordPage>
              </React.Suspense>
            }
          />
          <Route
            path="/VendorProfile"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyVendorProfile vendor={this.state.vendor} setVendor={this.setVendor}></LazyVendorProfile>
              </React.Suspense>
            }
          />

          <Route
            path="/seller"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazySellerPage></LazySellerPage>
                {/* <LazyShopPage user={this.state.user}  ></LazyShopPage> */}
              </React.Suspense>
            }
          />


          <Route
            path="/About"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyAboutPage> </LazyAboutPage>
              </React.Suspense>
            }
          />


          
<Route
            path="/productbysearch/:searchkey"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazySearchPage></LazySearchPage>
              </React.Suspense>
            }
          />
          
 

<Route
            path="/VendorVerification"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyVendorVerificationPage> </LazyVendorVerificationPage>
              </React.Suspense>
            }
          />

<Route
            path="/Profile"
            element={
              <React.Suspense
                fallback={
                  <center>
                    <div className="preloader">
                      <div className="lds-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </center>
                }
              >
                <LazyProfilePage></LazyProfilePage>
              </React.Suspense>
            }
          />




  {/* <Route exact path="/forget" render={(props) => <ForgetPasswordPage {...props} key={Date.now()} /> } />
 
 <Route exact path="/reset/:id" render={(props) => <ResetPasswordPage {...props} key={Date.now()} /> } />

 <Route exact path="/profile" render={(props) => <ProfilePage user={this.state.user} setUser={this.setUser}  {...props} key={Date.now()} /> } /> */}

        </Routes>
      </Fragment>
    );
  }
}
export default AppRoute;
