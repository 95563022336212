class AppURL {
     // static BaseURL = "https://maskantech.in/backend/public/api"
     // static BaseURLBackend = "https://maskantech.in/backend/public/"
     static BaseURL = "https://seller.globlix.com/api"
     static BaseURLBackend = "https://seller.globlix.com"


     // static BaseURL = "http://127.0.0.1:8000/api"
     // static BaseURLBackend = "http://127.0.0.1:8000"
     static VisitorDetails = this.BaseURL+"/getvisitor"
     static PostContact = this.BaseURL+"/save-contact"
     static AllSiteInfo = this.BaseURL+"/settings"
     static AllCategoryDetails = this.BaseURL+"/categories"
     static Shop = this.BaseURL+"/shop"
    

 
     static ProductListByRemark(Remark){
          return this.BaseURL+"/productlistbyremark/"+Remark;
     }

     static ProductListByCategory(category){
          return this.BaseURL+"/productlistbycategory/"+category;
     }
 
     static ProductListBySubCategory(category,subcategory){
          return this.BaseURL+"/productlistbysubcategory/"+category+"/"+subcategory;
     }

     static AllSlider = this.BaseURL+"/allslider"

     static ProductDetails(code){
          return this.BaseURL+"/productdetails/"+code;
     }
     static Auction = this.BaseURL+"/auction"
     static AuctionDetails(code){
          return this.BaseURL+"/auctiondetails/"+code;
     }
     static SendBid =  this.BaseURL+"/user/send-bid"
     static GetBid =  this.BaseURL+"/user/get-bid"
     // static GetBid =  this.BaseURL+"/user/get-bid/email"
// check this api on post man ye api kl nhi thi
// post man p ek baar check kro phle jsa kr
//  i will tell you
     static Events = this.BaseURL+"/event"
   
     static EventDetails(code){
          return this.BaseURL+"/eventdetails/"+code;
     }
     static EventRegister = this.BaseURL+"/eventregister"
     static EventRegistertList(email){
          return this.BaseURL+"/user/eventregistertlist/"+email;
     }
     static NotificationHistory = this.BaseURL+"/notification"

     static ProductBySearch(searchkey){
          return this.BaseURL+"/search/"+searchkey;
     }

     static CheckEmail(email){
          return this.BaseURL+"/check-email/"+email;
     }
     static CheckPhone(mobile){
          return this.BaseURL+"/check-mobile/"+mobile;
     }
 
     static UserVerify = this.BaseURL+"/user/verify"
     static UserLogin = this.BaseURL+"/user/login"
     static UserData = this.BaseURL+"/user/user"
     static UserRegister = this.BaseURL+"/user/register"
     static UserForgetPassword = this.BaseURL+"/user/forgetpassword"
     static UserResetPassword = this.BaseURL+"/user/resetpassword"
     static VendorRegister =  this.BaseURL+"/vendors/register"
     static VendorLogin =  this.BaseURL+"/vendors/login"
     static VendorVerify = this.BaseURL+"/vendors/verify"
     
     static SimilarProduct(code){
          return this.BaseURL+"/similar/"+code;
     }

     static ReviewList(code){
          return this.BaseURL+"/reviewlist/"+code;
     }
     static PostReview = this.BaseURL+"/user/postreview"
     static addToCart = this.BaseURL+"/user/addtocart"

     static CartCount(email){
          return this.BaseURL+"/user/cartcount/"+email;
     }

     static AddFavourite(product_code,email){
          return this.BaseURL+"/favourite/"+product_code+"/"+email;
     }

     static FavouriteList(email){
          return this.BaseURL+"/favouritelist/"+email;
     }

     static FavouriteRemove(product_code,email){
          return this.BaseURL+"/favouriteremove/"+product_code+"/"+email;
     }

     static CartList(email){
          return this.BaseURL+"/user/cartlist/"+email;
     }

     static RemoveCartList(id){
          return this.BaseURL+"/user/removecartlist/"+id;
     }

     static CartItemPlus(id,quantity,price){
          return this.BaseURL+"/user/cartitemplus/"+id+"/"+quantity+"/"+price;
     }

     static CartItemMinus(id,quantity,price){
          return this.BaseURL+"/user/cartitemminus/"+id+"/"+quantity+"/"+price;
     }
     static Validateorder = this.BaseURL+"/validate-order"
  
     static CartOrder = this.BaseURL+"/user/cartorder"

     static OrderListByUser(email){
          return this.BaseURL+"/user/orderlistbyuser/"+email;
     }


           
}

export default AppURL
 