import React, { Component, Fragment } from 'react'
import { Navbar, Container, Row, Col, Button, Dropdown } from 'react-bootstrap';

import Logo from '../../assets/images/easyshop.png';
import Bars from '../../assets/images/bars.png';
// import {Link, Redirect} from "react-router-dom";
import { Link, Navigate } from 'react-router-dom';
import MegaMenuAll from '../home/MegaMenuAll';
import MegaMenu from '../home/MegaMenu';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import Nav from 'react-bootstrap/Nav';


class NavMenuDesktop extends Component {

     constructor(props) {
          super(props);
          this.state = {
               SideNavState: "sideNavClose",
               ContentOverState: "ContentOverlayClose",
               Searchkey: "",
               SearchRedirectStauts: false,
               cartCount: 0,
               numberOfItem: 0,
               MenuData: [],
               // SliderData:[],
               isLoading: "",
               mainDiv: "d-none",
               // myemail: localStorage.getItem('email'),
               favCont : this.props.favCount
               // myemail : this.props.user
          }
          this.SearchOnChange = this.SearchOnChange.bind(this);
          this.SeachOnClick = this.SeachOnClick.bind(this);
          this.searchRedirect = this.searchRedirect.bind(this);

     }



     logout = () => {
          localStorage.clear();
     }



     componentDidMount() {
          let email = this.props.email;
          // alert(this.props.user);
          console.log('navigate', email);
          console.log("This is my Email", this.state.myemail)
          axios(AppURL.CartCount(this.state.myemail)).then((res) => {
               this.setState({ numberOfItem: res?.data })
               console.log("My Api is calling  : ", res?.data)
          })

          axios.get(AppURL.AllCategoryDetails).then(response => {
               this.setState({ MenuData: response.data });

          }).catch(error => {

          });

          // axios.get(AppURL.CartCount(email)).then((response)=>{
          //      this.setState({cartCount:response.data})

          //      console.log("count1", response.data); 
          // })

          

     }

     // Function to fetch and update the favorite count
           fetchFavoriteCount = () => {
                axios.get(AppURL.FavouriteList(this.props.user.email)).then(response => {
                    console.log("hhhhhhh : ",response)

                  const favoriteCount = response?.data?.length || 0;
                  this.setState({ favoriteCount });
                }).catch(error => {
                  console.error("Error fetching favorite count: ", error);
                });
              }


          //     addToFavorites = (product) => {
               // Your logic to add the product to favorites
             
               // Update the favorite count after adding
          //      this.fetchFavoriteCount();
          //    }
             
             // Function to remove a product from favorites
          //    removeFromFavorites = (product) => {
               // Your logic to remove the product from favorites
             
               // Update the favorite count after removing
               // this.fetchFavoriteCount((prevState)

          //      );
          //    }

     addToCart = (product) => {
          // Your cart logic to add the product
          this.setState((prevState) => ({
               cartCount: prevState.cartCount + 1,
          }));
     }

     // Example decrementing cart count
     removeFromCart = (product) => {
          // Your cart logic to remove the product
          this.setState((prevState) => ({
               cartCount: Math.max(0, prevState.cartCount - 1),
          }));
     }


     logout = () => {
          localStorage.clear();
     }

     SearchOnChange(event) {
          let Searchkey = event.target.value;
          // alert(Searchkey);
          this.setState({ Searchkey: Searchkey });
     }

     SeachOnClick() {
          if (this.state.Searchkey.length >= 2) {
               this.setState({ SearchRedirectStauts: true })
          }
     }

     searchRedirect() {
          if (this.state.SearchRedirectStauts === true) {
               return <Navigate to={"/productbysearch/" + this.state.Searchkey} />
          }
     }

     MenuBarClickHandler = () => {
          this.SideNavOpenClose();
     }

     ContentOverlayClickHandler = () => {
          this.SideNavOpenClose();
     }


     SideNavOpenClose = () => {
          let SideNavState = this.state.SideNavState;
          let ContentOverState = this.state.ContentOverState;
          if (SideNavState === "sideNavOpen") {
               this.setState({ SideNavState: "sideNavClose", ContentOverState: "ContentOverlayClose" })

          }
          else {
               this.setState({ SideNavState: "sideNavOpen", ContentOverState: "ContentOverlayOpen" })
          }
     }


     



     render() {
          let buttons;
          console.log("this is favount coint porps : " , this.state.favCont)
          if (localStorage.getItem('token')) {
               buttons = (
                    <div>


                         <Link to="/profile" className="h4 btn">PROFILE</Link>
                         <Link to="/" onClick={this.logout} className="h4 btn">LOGOUT</Link>
                         <Link to="/cart" className="cart-btn"> <i className="fa fa-shopping-cart"></i><sup><span className="badge text-white bg-danger">{this.state?.numberOfItem}</span></sup></Link>

                         <Link to="/favourite" className="btn"><i className="fa h4 fa-heart"></i><sup><span className="badge text-white bg-danger"> {this.state.favCont}</span></sup>
                         </Link>

                         <Link to="/notification" className="btn"><i className="fa h4 fa-bell"></i><sup><span className="badge text-white bg-danger"></span></sup>
                         </Link>

                    </div>
               )

          } else {
               buttons = (
                    <div>


                         <Link to="/login" className="h4 btn">LOGIN</Link>
                         <Link to="/register" className="h4 btn">SIGNUP</Link>

                         <Link to="/cart" className="cart-btn">
                              <i className="fa fa-shopping-cart"></i>
                              <sup>
                                   <span className={`badge text-white bg-danger ${this.state.cartCount > 0 ? 'blink' : ''}`}>
                                        {this.state.cartCount}
                                   </span>
                              </sup>
                         </Link>

                         <Link to="/notification" className="btn"><i className="fa h4 fa-bell"></i><sup><span className="badge text-white bg-danger"></span></sup>
                         </Link>
                         <Link to="/favourite" className="btn"><i className="fa h4 fa-heart"></i><sup><span className="badge text-white bg-danger"></span></sup>
                         </Link>
                    </div>
               )

          }
          return (
               <Fragment>
                    <div className="TopSectionDown">
                         <Navbar fixed={"top"} className="navbar" bg="light">

                              <Container fluid={"true"} className=" shadow-sm p-6 mb-2 bg-white">
                                   <Row>
                                        <Col lg={4} md={4} sm={12} xs={12}>


                                             {/* <img onClick={this.MenuBarClickHandler} className="bar-img" src={Bars} /> */}

                                             <Link to="/"> <img className="nav-logo" src={Logo} /> </Link>
                                        </Col>

                                        <Col className="p-1 pt-4 mt-1" lg={4} md={4} sm={12} xs={12}>
                                             <div className="input-group w-100">
                                                  <input onChange={this.SearchOnChange} type="text" className="form-control" />

                                                  <Button onClick={this.SeachOnClick} type="button" className="btn site-btn"><i className="fa fa-search"> </i>
                                                  </Button>
                                             </div>
                                        </Col>

                                        <Col className="p-1  pt-4 mt-1" lg={4} md={4} sm={12} xs={12}>

                                             {buttons}

                                        </Col>
                                        <Col className="  pt-2 " lg={12} md={4} sm={12} xs={12} class="main-menu">
                                             {/* <Nav className="justify-content-center" fill variant="underline"  activeKey="/home"> */}
                                             <Nav className="justify-content-center navbar-menu" variant="underline" activeKey="/home">
                                                  <Nav.Item>
                                                       {/* <Nav.Link href="/"> Home </Nav.Link> */}
                                                       <Nav.Link ><Link style={{ textDecoration: 'none', color: 'black' }} to="/">Home</Link>  </Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item>
                                                       {/* <Nav.Link href="/about"> About </Nav.Link> */}
                                                       <Nav.Link ><Link style={{ textDecoration: 'none', color: 'black' }} to="/about">About</Link>  </Nav.Link>
                                                  </Nav.Item>
                                                  
                                                  <Nav.Item>
                                                       {/* <Nav.Link to="/shop"> Shop </Nav.Link> */}
                                                       <Nav.Link ><Link style={{ textDecoration: 'none', color: 'black' }} to="/shop">Shop </Link>  </Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item>
                                                       <Nav.Link ><Link style={{ textDecoration: 'none', color: 'black' }} to="/seller">Seller</Link> </Nav.Link>
                                                       {/* <Nav.Link href="/seller"> Seller </Nav.Link> */}
                                                  </Nav.Item>
                                                  <Nav.Item>
                                                       <Nav.Link ><Link style={{ textDecoration: 'none', color: 'black' }} to="/contact">Contact</Link> </Nav.Link>
                                                       {/* <Nav.Link href="/contact"> Contact </Nav.Link> */}
                                                  </Nav.Item>
                                                  <Nav.Item>
                                                       {/* <Nav.Link ><Link  style={{textDecoration: 'none', color:'black'}} to="">Category</Link> </Nav.Link> */}
                                                       {/* <Col lg={4} md={3} sm={12} style={{ padding: '0px' }}>
                                                            <MegaMenu data={this.state.MenuData} /> */}
                                                            <Dropdown>
                                                                 <Dropdown.Toggle variant="" id="category-dropdown">
                                                                      <Link style={{ textDecoration: 'none', color: 'black' }}>Category</Link>
                                                                 </Dropdown.Toggle>
                                                                 <Dropdown.Menu  style={{width : "25rem"}} >
                                                                      <Col className='megamenu' lg={4} md={3} sm={12} style={{width : "100%"}}>
                                                                           <MegaMenu data={this.state.MenuData} />
                                                                      </Col>
                                                                      {/* Add more Dropdown.Item components for additional sublinks */}
                                                                 </Dropdown.Menu>
                                                            </Dropdown>
                                                       {/* </Col> */}
                                                  </Nav.Item>
                                                  {/* <Col lg={4} md={3} sm={12} style={{ padding: '0px' }}>
                                                       <MegaMenu data={this.state.MenuData} />
                                                  </Col> */}


                                             </Nav>
                                        </Col>
                                   </Row>
                                   {this.searchRedirect()}
                                   <Row>
                                        <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>



                                        </Col>


                                   </Row>
                              </Container>

                         </Navbar>
                    </div>
                    {/*                
                 <div className={this.state.SideNavState}>
                               <MegaMenuAll />
                         </div> */}

                    <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>

                    </div>



               </Fragment>
          )
     }
}

//                <Fragment>
// <div className="TopSectionDown">
// <Navbar fixed={"top"} className="navbar" bg="light">

//     <Container fluid={"true"} className="fixed-top shadow-sm p-2 mb-0 bg-white">
//          <Row>
//               <Col lg={4} md={4} sm={12} xs={12}>


//               <img onClick={this.MenuBarClickHandler} className="bar-img" src={Bars} />

//               <Link to="/"> <img className="nav-logo" src={Logo} /> </Link>
//               </Col>

//               <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>
//                    <div className="input-group w-100">
//                    <input type="text" className="form-control" />
//                    <Button type="button" className="btn site-btn"><i className="fa fa-search"> </i> 
//                    </Button>
//                    </div>
//               </Col>

//               <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>

//               <Link to="/favourite" className="btn"><i className="fa h4 fa-heart"></i><sup><span className="badge text-white bg-danger">3</span></sup>                  
//                    </Link>

//                    <Link to="/notification" className="btn"><i className="fa h4 fa-bell"></i><sup><span className="badge text-white bg-danger">5</span></sup>                  
//                    </Link>
//                    <a className="btn"><i className="fa h4 fa-mobile-alt"></i></a>
//                    <Link to="/login" className="h4 btn">LOGIN</Link>

//        <Link to="/cart" className="cart-btn"><i className="fa fa-shopping-cart"></i> 3 Items </Link>
//               </Col>

//          </Row>

//     </Container>

//   </Navbar>
//   </div>

//   <div className={this.state.SideNavState}>
//                 <MegaMenuAll />
//           </div>

//                <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>

//                </div>



//                </Fragment>
//           )
//      }
// }

export default NavMenuDesktop
