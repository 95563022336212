import React, { Component } from 'react' 
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
// import 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css';

class MegaMenu extends Component {

     constructor(){
          super();
          this.state ={
               MenuData:[]
          }
     }

     // constructor(){
     //      super();

     //      // this.MegaMenu = this.MegaMenu.bind(this);
     // }

     // componentDidMount(){
     //      this.MegaMenu();
     // }

     // MegaMenu(){
     //      var acc = document.getElementsByClassName("accordion");
     //      var accNum = acc.length;
     //      var i;
     //      for(i=0;i<accNum;i++){
     //           acc[i].addEventListener("click",function (){
     //                this.classList.toggle("active");
     //                var panel = this.nextElementSibling;
     //                if(panel.style.maxHeight){
     //                     panel.style.maxHeight = null;
     //                }else{
     //                     panel.style.maxHeight= panel.scrollHeight+ "px"
     //                }
     //           })
     //      }
     // }
     // MenuItemClick=(event)=>{
     //      event.target.classList.toggle("active");
     //      var panel = event.target.nextElementSibling;
     //      if(panel.style.maxHeight){
     //           panel.style.maxHeight = null;
     //      }else{
     //           panel.style.maxHeight= panel.scrollHeight+ "px"
     //      }

     // }

     render() {

          const CatList = this.props.data;

          // const MyView = CatList.map((CatList,i)=>{
          //      return  <div key={i}> {/* Use the index as the key */}
          //           <Accordion.Item eventKey={i.toString()}> {/* Convert the index to a string */}
                   
          //                <Accordion.Header> <img className="accordionMenuIcon" src={CatList.category_image} />&nbsp; {CatList.category_name}</Accordion.Header>             
          //                     <Accordion.Body>
          //                     <ul className="accordion-list">
          //                          {
          //                               (CatList.subcategory).map((SubList,i)=>{
          //                                    console.log('SubList',SubList); return <li>
                                                 
          //                                              {/* <Link to={"productsubcategory/"+CatList.category_name+"/"+SubList.title } className="accordionItem" >{SubList.title} </Link> */}
          //                                              <Link to={"shop" } className="accordionItem" >{SubList.title} </Link>
          //                                         </li>

          //                               })    
          //                          }
                                   
          //                     </ul>
                              
          //                     </Accordion.Body>
          //             </Accordion.Item>
          //             </div>

          //      });
          const sidebarStyle = {
               height: '30vh', // Set the desired height for your sidebar
               overflowY: 'scroll',
             };

          const MyView = CatList.map((CatList, i) => {
               return (
                 <div key={i}>
                   {/* Use the index as the key */}
                   <Accordion.Item className='Accordian' eventKey={i.toString()}> {/* Convert the index to a string */}
                     <Accordion.Header className='accordian-header'>
                       <img className="accordionMenuIcon" src={CatList.category_image} />
                       &nbsp; {CatList.category_name}
                     </Accordion.Header>
                     <Accordion.Body>
                       <ul className="accordion-list">
                         {CatList.subcategory.map((SubList, j) => {
                           console.log('SubList', SubList);
                           return (
                             <li key={j}>
                               <Link to={"shop"} className="accordionItem">
                                 {SubList.title}
                               </Link>
                             </li>
                           );
                         })}
                       </ul>
                     </Accordion.Body>
                   </Accordion.Item>
                 </div>
                
               );
             });



          return (

//               <div className="accordionMenuDiv">
//                    <div className="accordionMenuDivInside">


//            <button className="accordion">
//                  <img className="accordionMenuIcon" src="https://image.flaticon.com/icons/png/128/739/739249.png" />&nbsp; Men's Clothing 
//                         </button>
// <div className="panel">
//      <ul>
//           <li><a href="#" className="accordionItem" > Mans Tshirt 1</a></li>
//           <li><a href="#" className="accordionItem" > Mans Tshirt 2</a></li>
//      </ul>
// </div>
 

//                    </div>

//               </div>
<Container>


<Accordion>
     
     
  
     {/* <Accordion.Item eventKey="1">
     <Accordion.Header>Accordion Item #2</Accordion.Header>
     <Accordion.Body>
     <li>sadsjad</li>
               <li>sadsjad</li> 
               <li>sadsjad</li>
     </Accordion.Body>
     </Accordion.Item> */}
{/* <div class="container vertical-scrollable"> */}
                {/* <div class="row text-center"> */}
                    {/* <div class="col-sm-8">
                        1
                      </div> */}
                     
       <div className='sidebarStyle' style={sidebarStyle}>
            {MyView}
          </div>
                {/* </div> */}
            {/* </div> */}
       
 


</Accordion>

</Container>

          )
     }
}

export default MegaMenu
